<template>
  <div>
    <section>
      <div class="row">
        <div class="col-xs-12">
          <h1>Buttons</h1>
          <p>
            The button element represents a clickable button, used to submit forms or anywhere
            in a document for accessible, standard button functionality.
            By default, HTML buttons are presented in a style resembling the platform the
            user agent runs on, but you can change buttons’ appearance with CSS.
          </p>
        </div>
      </div>
    </section>

    <section>
      <div class="row">
        <div class="col-xs-6">
          <div class="u-Image first" />
        </div>
        <div class="col-xs-6">
          <div class="u-Image second" />
        </div>
      </div>
    </section>

    <section>
      <div class="row">
        <div class="col-xs-12">
          <h3>Usage</h3>
          <p>
            The button element represents a clickable button, used to submit forms or anywhere
            in a document for accessible, standard button functionality.
          </p>
          <a href="https://samora.netlify.app/#/Button" target="_blank">Full technical details</a>
          <br>
          <br>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <div class="row">
              <div class="col-xs-12">
                <h4>Styles</h4>
              </div>
              <div class="col-xs-3">
                <p>Primary</p>
                <Button
                  label="hello"
                  button-style="is-primary"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-primary"
                  button-type="is-outlined"
                />
                <br>
              </div>
              <div class="col-xs-3">
                <p>Primary Inverted</p>
                <Button
                  label="hello"
                  button-style="is-primary-inverted"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-primary-inverted"
                  button-type="is-outlined"
                />
                <br>
              </div>
              <div class="col-xs-3">
                <p>Transparent</p>
                <Button
                  label="hello"
                  button-style="is-transparent"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-transparent"
                  button-type="is-outlined"
                />
                <br>
              </div>
              <div class="col-xs-3">
                <p>Added</p>
                <Button
                  label="hello"
                  button-style="is-added"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-added"
                  button-type="is-outlined"
                />
                <br>
              </div>
              <!-- is-important, is-alert, is-alert-dark, is-gray-800 -->
              <div class="col-xs-3">
                <p>Important</p>
                <Button
                  label="hello"
                  button-style="is-important"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-important"
                  button-type="is-outlined"
                />
                <br>
              </div>
              <div class="col-xs-3">
                <p>Alert</p>
                <Button
                  label="hello"
                  button-style="is-alert"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-alert"
                  button-type="is-outlined"
                />
                <br>
              </div>
              <div class="col-xs-3">
                <p>Alert Dark</p>
                <Button
                  label="hello"
                  button-style="is-alert-dark"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-alert-dark"
                  button-type="is-outlined"
                />
                <br>
              </div>
              <div class="col-xs-3">
                <p>Gray 800</p>
                <Button
                  label="hello"
                  button-style="is-gray-800"
                />
                <p>
                  <em>Outlined</em>
                </p>
                <Button
                  label="hello"
                  button-style="is-gray-800"
                  button-type="is-outlined"
                />
                <br>
              </div>
          </div>
        </div>
      </div>
      <br>
      <hr>
    </section>

  </div>
</template>

<script>
import { Button } from '@unbabel/ui';

export default {
  components: {
    Button,
  },
};
</script>

<style lang="scss" scoped>
section {
  margin-bottom: 2rem;

  &:not(:first-child) {
    margin-bottom: 4rem;
  }
}

.u-ImageWrapper {
  position: relative;
}

.u-Image {
  @include imageCard;
  border: 1px solid #DDD;

  &.first {
    background-image: url("~@/assets/images/button_example.png");
  }

  &.second {
    background-image: url("~@/assets/images/button_example2.png");
  }
}
</style>
